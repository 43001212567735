<template>
  <div>
    <v-navigation-drawer :value="drawer" v-click-outside="onClickOutside" width="420" height="100%" bottom right fixed>
      <v-list dense>
        <v-list-item dense>
          <v-list-item-action>
            <v-btn icon title="Назад" @click="$emit('close-appeal')">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-form
        ref="appealForm"
        v-model="appealFormVal"
        @submit.prevent="sendRequest()"
      >
        <v-col align="center">
          <h4>Создать заявку</h4>
          <v-row dense>
            <v-col cols="12" sm="4">
              <v-card-text class="text-start">Фамилия</v-card-text>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field
                v-model.trim="lastName"
                type="text"
                outlined
                dense
                hide-details
                clearable
                :rules="requiredRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card-text class="text-start">Имя</v-card-text>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field
                v-model.trim="firstName"
                type="text"
                outlined
                dense
                hide-details
                clearable
                :rules="requiredRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card-text class="text-start">Отчество</v-card-text>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field
                v-model.trim="patronym"
                type="text"
                outlined
                dense
                hide-details
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card-text class="text-start">Email</v-card-text>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field
                v-model="email"
                outlined
                dense
                hide-details
                clearable
                :rules="emailRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card-text class="text-start">Номер телефона</v-card-text>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field
                v-model="phone"
                type="phone"
                placeholder="8XXXXXXXXXX"
                outlined
                dense
                hide-details
                clearable
                :rules="phoneRules"
                required
              ></v-text-field>
            </v-col>
            <v-col>
              <v-textarea
                placeholder="Текст заявки"
                v-model="body"
                outlined
                auto-grow
                :rules="bodyRules"
                required
              ></v-textarea>
            </v-col>
          </v-row>
          <v-col class="pa-0">
            <v-file-input
              v-model="appealImg"
              type="file"
              class="input"
              accept="appealImg/*"
              show-size
              placeholder="Добавить фото"
              prepend-icon="mdi-camera"
              outlined
              dense
              hide-details
              @change="uploadAppealImg"
            />
          </v-col>
          <v-col>
            <v-img
              :src="appealImgUrl"
              style="border: 1px #ccc; max-height: 100px; max-width: 100px"
            />
          </v-col>
          <v-row dense justify="center">
            <v-col cols="12" sm="6">
              <v-card-text class="text-center">
                <v-btn
                  type="submit"
                  class="font-weight-bold"
                  :disabled="!appealFormVal"
                  >Отправить</v-btn>
              </v-card-text>
            </v-col>
          </v-row>
        </v-col>
      </v-form>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  props: {
    drawer: Boolean,
    setAppeal: Function
  },
  data() {
    return {
      appealFormVal: false,
      lastName: "",
      firstName: "",
      patronym: "",
      email: "",
      phone: "",
      body: "",
      appealImg: undefined,
      appealImgUrl: "",
      requiredRules: [value => !!value || "Обязательное поле"],
      bodyRules: [
        value => !!value || "Обязательное поле",
        value =>
          (value || "").length <= 250 ||
          "Описание должно содержать не более 250 символов"
      ],
      emailRules: [
        value => !!value || "Обязательное поле",
        value =>
          (!!value && value.includes("@")) ||
          "Электронная почта должна содержать символ @"
      ],
      phoneRules: [
        value => !!value || "Обязательное поле",
        value =>
          (!!value && value.length === 11) ||
          "Номер телефона должен состоять из 11 цифр"
      ]
    };
  },

  methods: {
    onClickOutside(e) {
      if (e.target.className === "v-overlay__scrim") {
        if (this.drawer) this.$emit('close-filters');
      }
    },
    async sendRequest() {
      await this.setAppeal({
        full_name: this.lastName + ' ' + this.firstName + ' ' + this.patronym,
        email: this.email,
        phone: this.phone,
        body: this.body,
        image: this.appealImg
      });

      this.lastName = "";
      this.firstName = "";
      this.patronym = "";
      this.email = "";
      this.phone = "";
      this.body = "";
      this.appealImg = undefined;
      this.appealImgUrl = "";
      this.appealFormVal = false;
    },
    uploadAppealImg(file) {
      if (!file) {
        return;
      }
      const reader = new FileReader();
      reader.onload = e => {
        this.appealImgUrl = e.target.result;
      };
      reader.onerror = function(error) {
        alert(error);
      };
      reader.readAsDataURL(file);
    }
  }
};
</script>
